import React from 'react';
import styles from './Note.module.css';

function Note({ response, onClick, avatar }) {
    const truncateResponse = (response) => {
        if (response.length > 150) {
            return response.substring(0, 150) + '...';
        }
        return response;
    };

    return (
        <div className={styles.note} onClick={onClick}>
            <div className={styles.leftColumn}>
                <img src={avatar} alt="Avatar" className={styles.avatar} />
            </div>
            <div className={styles.rightColumn}>
                <p className={styles.answer}>
                    <span dangerouslySetInnerHTML={{ __html: truncateResponse(response) }} />
                </p>
            </div>
        </div>
    );
}

export default Note;
