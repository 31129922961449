import React, { useState, useRef, useEffect } from 'react';
import styles from './LanguageSelector.module.css';

const LanguageSelector = ({ onLanguageChange }) => {
  const [targetLang, setTargetLang] = useState('English');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleChange = (e) => {
    const newLang = e.target.value;
    setTargetLang(newLang);
    onLanguageChange(newLang);
    setShowDropdown(false);
  };

  const handleLabelClick = () => {
    setShowDropdown(true);
  };

  useEffect(() => {
    if (showDropdown && dropdownRef.current) {
      dropdownRef.current.focus();
      dropdownRef.current.size = dropdownRef.current.options.length; // Open the dropdown list
    }
  }, [showDropdown]);

  return (
    <div className={styles.languageSelector}>
      {!showDropdown && (
        <div className={styles.label} onClick={handleLabelClick}>
          Language | {targetLang}
        </div>
      )}
      {showDropdown && (
        <select
          id="language-select"
          className={styles.dropdown}
          value={targetLang}
          onChange={handleChange}
          ref={dropdownRef}
          onBlur={() => setShowDropdown(false)} // Close dropdown when it loses focus
        >
          <option value="English">English</option>
          <option value="Korean">Korean</option>
          <option value="Japanese">Japanese</option>
          <option value="Vietnamese">Vietnamese</option>
          <option value="Spanish">Spanish</option>
          <option value="French">French</option>
          <option value="German">German</option>
          <option value="Hindi">Hindi</option>
          <option value="Urdu">Urdu</option>
          <option value="Portuguese">Portuguese</option>
          <option value="Chinese">Chinese</option>
        </select>
      )}
    </div>
  );
};

export default LanguageSelector;
